import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Endpoints, get } from '../api';
import HighscoreItem from '../components/HighscoreItem';
import Divider from '../components/shared/Divider';
import Spacer from '../components/shared/Spacer';
import { ErrorContext } from '../contexts/ErrorContext';
import { getAllUsers } from '../services/firebase';
import { getName } from '../services/localStorage';

const SubTitle = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const StyledDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.2);
  width: 70%;
  display: block;
  margin: 0 auto;
`;

const ContainerHighscore = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface LeadboardItem {
  name: string;
  score: number;
}

const Highscore = () => {
  const [leaderboard, setLeaderboard] = useState<LeadboardItem[]>();
  const [currentUserXpGained, setCurrentUserXpGained] = useState(0);
  const { handleError } = useContext(ErrorContext);
  const user = getName();

  const getLeaderboard = async () => {
    const data = await get(Endpoints.LEADERBOARD);
    const users = await getAllUsers();

    if (!!data && !!users) {
      setLeaderboard(
        users
          .reduce((acc, user) => {
            const leaderboardUser = data.find(
              (item) => item.productUserId === user.gwenId
            );
            if (!!leaderboardUser) {
              return [
                ...acc,
                {
                  name: user.nickname,
                  score: leaderboardUser['xp-pointsGained']
                }
              ];
            } else {
              return acc;
            }
          }, [])
          .sort((a, b) => b.score - a.score)
      );
    } else if (!data) {
      handleError('Could not get leaderboard...');
    }
  };

  const getCurrentUserXpGained = async () => {
    let data = await get(Endpoints.LEADERBOARD_ME);
    if (!data || !data['xp-pointsGained']) return;
    setCurrentUserXpGained(data['xp-pointsGained']);
  };

  useEffect(() => {
    if (!!user) {
      getCurrentUserXpGained();
      getLeaderboard();
    }
  }, []);

  return (
    <>
      <ContainerHighscore>
        {!!user ? (
          leaderboard?.length > 0 ? (
            leaderboard
              ?.slice(0, 11)
              .map((item, index) => (
                <HighscoreItem
                  key={item.name}
                  name={item.name}
                  position={index + 1}
                  score={item.score}
                />
              ))
          ) : (
            <SubTitle>There are no registered highscores</SubTitle>
          )
        ) : (
          <SubTitle>Register to view highscore</SubTitle>
        )}
      </ContainerHighscore>
      {!!user && (
        <>
          <Spacer spacing={2} />
          <StyledDivider />
          <Spacer />
          <SubTitle>{`${currentUserXpGained} xp - ${user}`}</SubTitle>
          <SubTitle style={{ color: 'grey' }}>{`(this is you)`}</SubTitle>
          <Spacer />
          <Divider />
        </>
      )}
    </>
  );
};

export default Highscore;
