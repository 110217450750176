import React from 'react';
import styled from 'styled-components';
import Breakpoints from '../services/breakpoints';

interface IHighScoreItem {
  position: number;
  name: string;
  score: number;
}

const getColor = (position: number): string => {
  switch (position) {
    case 1:
      return '#FFD700';
    case 2:
      return '#C4D4E0';
    case 3:
      return '#CD7F32';
    default:
      '#FFF';
  }
};

const Title = styled.p`
  font-size: 1.2rem;
  text-align: left;
`;

const Container = styled.div`
  width: 210px;
  margin: auto;
  display: block;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    width: 172px;
  }
`;

const HighscoreItem: React.FC<IHighScoreItem> = ({ position, name, score }) => (
  <Container>
    <Title>
      <span style={{ color: getColor(position) }}>{`#${position}`}</span> -{' '}
      {`${name} ${score ? `(${score} xp)` : ''}`}
    </Title>
  </Container>
);

export default HighscoreItem;
