import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../components/shared/Button';
import Container from '../components/shared/Container';
import Spacer from '../components/shared/Spacer';
import { navigate } from 'gatsby';
import { AchievementData } from '@insertcoinab/gwen-react-components/dist/types';
import { get, Endpoints } from '../api';
import { ErrorContext } from '../contexts/ErrorContext';
import styled from 'styled-components';
import PageHeader from '../components/shared/PageHeader';
import Divider from '../components/shared/Divider';
import { getHasPlayed, getName, saveHasPlayed } from '../services/localStorage';
import AchievementItem from '../components/AchievementItem';
import Highscore from '../components/Highscore';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const ContainerInner = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const ContainerAchievements = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  column-gap: 16px;
  grid-template-columns: 80px 80px 80px;
  width: 80%;
  margin: 0 auto;
`;

const End = () => {
  const { handleError } = useContext(ErrorContext);
  const [achievements, setAchievements] = useState<AchievementData[]>();
  const user = getName();

  const getAchievements = async () => {
    const data = await get(Endpoints.ACHIEVEMENT);
    if (!!data) {
      setAchievements(data);
    } else {
      handleError('Could not get achievements...');
    }
  };

  useEffect(() => {
    if (getHasPlayed() !== 'true') {
      saveHasPlayed();
    }
  }, []);

  useEffect(() => {
    getAchievements();
  }, []);

  const handleContinue = () => {
    navigate('/register');
  };

  const itemsFound = useMemo(() => {
    if (achievements?.length > 0) {
      return achievements.reduce(
        (tot, achievement) => tot + (achievement.completed ? 1 : 0),
        0
      );
    }
    return 0;
  }, [achievements]);

  return (
    <StyledContainer>
      <PageHeader title="GAME SUMMARY" hasBackButton={false} />
      <ContainerInner>
        <Title>ITEMS FOUND</Title>
        <SubTitle>{`${itemsFound} / ${achievements?.length ?? 0}`}</SubTitle>
        <Spacer />
        <ContainerAchievements>
          {achievements
            ?.filter((ach) => !!ach.completed)
            .map((achievement) => (
              <AchievementItem key={achievement.id} icon={achievement.icon} />
            ))
            .slice(0, 3)}
        </ContainerAchievements>
        <Spacer spacing={2} />
        <Button onClick={() => navigate('/backpack')}>
          View all items &#8250;
        </Button>
        {!user && (
          <>
            <Spacer spacing={2} />
            <SubTitle>Register to save progress</SubTitle>
          </>
        )}
        <Spacer spacing={2} />
        <Divider />
        <Spacer />
        <Title>HIGHSCORE</Title>
        <Spacer />
        <Highscore />
        <Spacer spacing={2} />
      </ContainerInner>
      {!!user ? (
        <Button primary variant="large" onClick={() => navigate('/')}>
          Keep exploring
        </Button>
      ) : (
        <>
          <Button primary variant="large" onClick={handleContinue}>
            Register to talk future
          </Button>
          <Spacer spacing={3} />
          <Button variant="large" onClick={handleContinue}>
            Register to keep exploring
          </Button>
        </>
      )}
    </StyledContainer>
  );
};

export default End;
