import React from 'react';
import styled, { css } from 'styled-components';
import Breakpoints from '../services/breakpoints';

const Container = styled.img<{ disabled: boolean }>`
  height: 60px;
  width: 60px;
  object-fit: contain;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    height: 45px;
    width: 45px;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`;

interface IAchievementItem {
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const AchievementItem: React.FC<IAchievementItem> = ({
  icon = '',
  onClick,
  disabled = false
}) => <Container src={icon} onClick={onClick && onClick} disabled={disabled} />;

export default AchievementItem;
